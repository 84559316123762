import { Layout, Menu, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import DarkModeToggle from "react-dark-mode-toggle";
import Link from "next/link";
import {
  MenuTwoTone,
  MenuOpenTwoTone,
  ExpandMore,
  SecurityTwoTone,
  SettingsApplications,
  RecentActorsTwoTone,
  FaceTwoTone,
  WbIridescentTwoTone,
  PowerSettingsNewTwoTone,
  ToggleOnTwoTone,
  ToggleOffTwoTone,
  HelpTwoTone,
  QuestionAnswerTwoTone,
  Brightness4TwoToneIcon,
  LightModeTwoToneIcon
} from "@material-ui/icons";
import styled from "styled-components";

import DashHeader from "./styles/Header";
import UserAvatar from "./UserAvatar";
import { useAppState } from "./shared/AppProvider";
import Gaurd from "./Auth/Gaurd";
import DomainControl from "../components/Domain/DomainControl";
import Favicon from "../components/Domain/Favicon";
import { toggleMenuBar } from "../store/actions/configActions";
import { logoutUser } from "../store/actions/authActions";
import Notifications from "./Notifications/Notifications";

const { SubMenu } = Menu;
const { Header } = Layout;

const MainHeader = () => {
  const dispatcher = useDispatch();
  const [state, dispatch] = useAppState();
  const menuState = useSelector((state) => state.config.menuBar);
  const domain = useSelector((state) => state.domains.currentDomain);

  const toggleMenu = () => {
    dispatcher(toggleMenuBar(!menuState));
  };

  const logOutUser = () => {
    dispatcher(logoutUser());
  };

  const iconStyle = { color: "#002f53", marginRight: "10px" };

  const displayToggleIcon = (isChecked) => {
    return isChecked ? (
      <ToggleOnTwoTone style={{ color: "#f3452e", fontSize: "30px" }} />
    ) : (
      <ToggleOffTwoTone style={{ color: "#002f53", fontSize: "30px" }} />
    );
  };

  const TagStyles = styled.div`
    display: contents;
    .tag {
      background-color: #f0f0f0;
      height: 34px;

      &:hover {
        opacity: 1;
      }
    }
  `;

  return (
    <DashHeader>
      <Gaurd />
      <Header
        style={{
          backgroundColor: state.darkSidebar && "#333333",
        }}
      >
        {state.mobile ? (
          <a
            onClick={() => dispatch({ type: "mobileDrawer" })}
            className="trigger"
          >
            <MenuTwoTone style={{ ...iconStyle, fontSize: "26px" }} />
          </a>
        ) : (
          <a
            onClick={() => toggleMenu()}
            className="trigger"
            style={{
              marginLeft: menuState ? "19px" : "12px",
              marginRight: "160px",
            }}
          >
            {menuState ? (
              <MenuTwoTone style={{ ...iconStyle, fontSize: "26px" }} />
            ) : (
              <MenuOpenTwoTone style={{ ...iconStyle, fontSize: "26px" }} />
            )}
          </a>
        )}

        {domain?.name && (
          <TagStyles>
            <Tag className="tag">
              <div
                style={{
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Favicon domain={domain} />
              </div>
            </Tag>
          </TagStyles>
        )}
        {/* <Link href="/">
          <a className="brand">
            <img src='/logo.svg' alt='logo' width='100' />
          </a>
        </Link> */}
        <Menu
          mode="horizontal"
          className="menu"
          theme={state.darkSidebar ? "dark" : "light"}
        >
          <TagStyles>
            <Tag className="tag">
              <DomainControl state={state} />
            </Tag>
          </TagStyles>
        </Menu>

        {/* <Menu mode="horizontal" className="menu-divider">
          {!state.mobile && (
            <Menu.Item>
              <Link href="/apps/calendar">
                <a>Calendar</a>
              </Link>
            </Menu.Item>
          )}

          {!state.mobile && (
            <Menu.Item>
              <Link href="/apps/messages">
                <a>Messages</a>
              </Link>
            </Menu.Item>
          )}

          {!state.mobile && (
            <Menu.Item>
              <Link href="/apps/social">
                <a>Social</a>
              </Link>
            </Menu.Item>
          )}

          {!state.mobile && (
            <Menu.Item>
              <Link href="/apps/chat">
                <a>Chat</a>
              </Link>
            </Menu.Item>
          )}

          {state.mobile && (
            <SubMenu title={<ChevronsDown size={20} strokeWidth={1} />}>
              <Menu.Item>Calendar</Menu.Item>
              <Menu.Item>Messages</Menu.Item>
              <Menu.Item>Social</Menu.Item>
              <Menu.Item>Chat</Menu.Item>
            </SubMenu>
          )}
        </Menu> */}

        <span className="mr-auto" />
        {!state.mobile && (
          <>
            <Menu
              mode="horizontal"
              theme={state.darkSidebar ? "dark" : "light"}
            >
              <SubMenu className={state.darkSidebar ? "dddd" : "wwww"}
                title={
                  <>
                    <HelpTwoTone style={iconStyle} />
                    Help
                    <ExpandMore style={iconStyle} />
                  </>
                }
              >
                <Menu.Item key="getHelp" >
                  <Link href="#">
                    <a>
                      <QuestionAnswerTwoTone style={iconStyle} />
                      Get Help
                    </a>
                  </Link>
                </Menu.Item>
                <Menu.Item key="getHelp">
                  <Link href="#">
                    <a>
                      <QuestionAnswerTwoTone style={iconStyle} />
                      Getting Started Guide
                    </a>
                  </Link>
                </Menu.Item>
                <Menu.Item key="getHelp">
                  <Link href="#">
                    <a>
                      <QuestionAnswerTwoTone style={iconStyle} />
                      System Status
                    </a>
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                title={
                  <DarkModeToggle
                    onChange={(checked) =>
                      dispatch({ type: "weak", value: checked })
                    }
                    checked={!!state.weakColor}
                    size="40px"
                  />
                }
              >
              </SubMenu>
            </Menu>
            <Menu
              mode="horizontal"
              theme={state.darkSidebar ? "dark" : "light"}
            >
              <SubMenu
                title={
                  <>
                    <UserAvatar />
                    <span className="ml-2">
                      <ExpandMore fontSize="large" />
                    </span>
                  </>
                }
              >
                <Menu.ItemGroup
                  key="g1"
                  title={
                    <>
                      Account
                      <SettingsApplications
                        style={{ color: "#002f53", marginLeft: "10px" }}
                      />
                    </>
                  }
                >
                  <Menu.Item key="subscription">
                    <Link href="/subscription">
                      <a>
                        <RecentActorsTwoTone style={iconStyle} />
                        Subscription
                      </a>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="portfolio">
                    <FaceTwoTone style={iconStyle} />
                    <Link href="/users/portfolio">Portfolio</Link>
                  </Menu.Item>
                  <Menu.Item key="Notifications">
                    <Notifications />
                  </Menu.Item>
                  <Menu.Item key="security">
                    <Link href="/users/updatepassword">
                      <a>
                        <SecurityTwoTone style={iconStyle} />
                        Security
                      </a>
                    </Link>
                  </Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup
                  key="g2"
                  title={
                    <>
                      Display Preferences
                      <WbIridescentTwoTone
                        style={{ color: "#002f53", marginLeft: "10px" }}
                      />
                    </>
                  }
                >
                  
                  {/* <Menu.Item key="2">
                    <span onClick={() => dispatch({ type: "sidebarTheme" })}>
                      {displayToggleIcon(state.darkSidebar)}
                    </span>
                    <span className="ml-2">Dark sidebar menu</span>
                  </Menu.Item> */}
                  <Menu.Item key="3">
                    <span onClick={() => dispatch({ type: "sidebarPopup" })}>
                      {displayToggleIcon(state.sidebarPopup)}
                    </span>
                    <span className="ml-2">Popup sub menus</span>
                  </Menu.Item>
                </Menu.ItemGroup>

                <Menu.Item onClick={() => logOutUser()}>
                  <PowerSettingsNewTwoTone style={iconStyle} />
                  Signout
                </Menu.Item>
              </SubMenu>
            </Menu>
          </>
        )}
      </Header>
    </DashHeader>
  );
};

export default MainHeader;
